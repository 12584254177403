.card {
  position: relative;
  opacity: 0.4;
  transition: all 0.4s ease;
  &:hover {
    opacity: 0.6
  }
}

.image {
  width: 100%;
  margin-top: 15px;
  height: 150px;
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
}

.remove {
  cursor: pointer;
  position: absolute;
  padding: 10px;
  top: 0;
  right: 0;
  transition: all 0.4s ease;
  font-size: 20px;
  z-index: 5;
  &:hover {
    color: red;
  }
}