.container {
  position: relative;
  text-align: center;
  margin: 10px;
  display: inline-block;
  height: auto;
  border: 1px solid #7f7f7f40;
  background: white;
  padding: 20px 0 0;
  width: 200px;
}

.image {
  position: relative;
  width: 132px;
  height: 132px;
  text-align: center;
  display: inline-block;
  background-size: cover;
  background-position: center;
  &:hover .editLayer{
    opacity: 1;
  }
}

.editLayer {
  position: absolute;
  height: 100%;
  width: 100%;
  background: rgba(0,0,0, 0.5);
  opacity: 0;
  transition: all 0.4s ease;
}

.input,
.selectOption {
  margin: 10px 10px 20px;
  display: inline-block;
  width: calc(100% - 20px);
  text-align: center;
}

.inactiveInput {
  border-color: transparent;
}

.static {
  width: 100px;
  height: 100px;
  position: relative;
  display: inline-block;
  border: 1px solid #40b395;
  overflow: hidden;
  border-radius: 50px;
  margin: 10px;
  background: #fff;
  cursor: pointer;
  transition: all 0.4s ease;
  padding: 10px;
  &:hover {
    box-shadow: 0 0 5px black;
  }
  img {
    width: 100%;
    height: auto;
  }
}