.button {
  width: 100%;
  font-size: 24px;
  height: auto;
  padding: 5px;
  text-transform: capitalize;
}

.later {
  background-color: #000 !important;
  color: rgb(64, 179, 149) !important;
  border-color: black !important;
  &:hover {
    background-color: rgba(0,0,0,0.8) !important;
  }
}