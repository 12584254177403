.container {
  position: relative;
  text-align: center;
  margin: 10px;
  display: inline-block;
  height: auto;
  border: 1px solid #7f7f7f40;
  background: white;
  padding: 20px 0 20px;
  width: 200px;
}

.uploader {
  display: inline-block;
  margin: auto;
  margin-right: 0 !important;
  height: 132px !important;
  div {
    margin-right: 0 !important;
  }
}

.image {
  position: relative;
  width: 132px;
  height: 132px;
  text-align: center;
  display: inline-block;
  margin-bottom: 5px;
}

.icon {
  display: inline-block;
  color: #fff;
  cursor: pointer;
  transition: all 0.4s ease;
  font-size: 20px;
  margin: 46px 9px 0;
  border: 1px solid #fff;
  border-radius: 50px;
  padding: 11px;
}

.input,
.selectOption {
  margin: 10px 10px 20px;
  display: inline-block;
  width: calc(100% - 20px);
  text-align: center;
}

:global {
  .basic-uploader .ant-upload-picture-card-wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
  }
}