.tag {
  cursor: pointer;
  color: #fff;
  padding: 7px 11px;
  font-size: 16px;
  &:hover {
    color: #fff !important;
  }
}

.dotsOnly {
  padding: 0;
  height: 25px;
  width: 25px;
  margin: 10px 5px 0;
  border-radius: 50px;
}