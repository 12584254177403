.uploader {
  display: inline-block;
  margin: auto;
  margin-right: 0 !important;
  height: 132px !important;
  div {
    margin-right: 0 !important;
  }
}

.image {
  position: relative;
  width: 132px;
  height: 132px;
  text-align: center;
  display: inline-block;
  background-size: cover;
  background-position: center;
  &:hover .editLayer{
    opacity: 1;
  }
}

.editLayer {
  position: absolute;
  height: 100%;
  width: 100%;
  background: rgba(0,0,0, 0.5);
  opacity: 0;
  transition: all 0.4s ease;
}

.icon {
  display: inline-block;
  color: #fff;
  cursor: pointer;
  transition: all 0.4s ease;
  font-size: 20px;
  margin: 46px 9px 0;
  border: 1px solid #fff;
  border-radius: 50px;
  padding: 11px;
}

.deleteHover {
  &:hover {
    color: #ee9090;
    border-color: #ee9090;
  }
}

.editHover {
  &:hover {
    color: lightgreen;
    border-color: lightgreen;
  }
}