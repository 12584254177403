.logo {
  width: 100%;
  background-color: #222;
  height: auto;
  padding: 19px 40px 20px;
  min-height: 64px;
  max-height: 64px;
}

.menuItem {
  &:hover {
    a {
      color: #40b395;
    }
    color: #40b395;
  }
}

.link {
  display: inline-block !important;
}