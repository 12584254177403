.listItem {
  padding: 10px;
  &:hover {
    background: #eee;
  }
}

.delete {

}

.edit {

}

.icon {
  &:hover {
    color: #ff8686;
  }
}